<!DOCTYPE html>
<html>
  <head>
    <base href="./" />

    <meta charset="utf-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />

    <link rel="icon" id="app_icon" type="image/png" href="/assets/favicon.png" />
  </head>

  <body>
    <div>
      <div class="flex">
        <div class="flex-none">
          <!-- <app-guard-menu-left> -->
        </div>

        <div class="flex-grow">
          <app-guard-header>
            <!-- Menu Header -->
          </app-guard-header>

          <router-outlet>
            <!-- Page content -->
          </router-outlet>
        </div>
      </div>
    </div>

    <div>
      <app-guard-footer>
        <!-- Footer -->
      </app-guard-footer>
    </div>
  </body>
</html>
